/* For mobile devices: */
@media only screen and (max-width: 1100px) {
	.App {
		max-width: fit-content;
	}
	.header-spacer {
		/* border: 1px solid blue; */
		height: 10rem;
		width: 100%;
		max-width: 100%;
	}
	.navbar-logo {
		height: 8rem;
		width: 8rem;
		margin-left: 2rem;
		margin-right: unset;
		padding: 0;
	}

	.navbar-item,
	.flc-button {
		font-size: 1rem;
	}

	.description-large {
		font-size: 2.5rem;
		margin: 0 1rem;
	}

	.description-small {
		font-size: 1.5rem;
	}

	/* .home-container {
		background-size: 2200px;
	} */

	/* .home-page-description {
		height: 200px;
	} */

	.page-container {
		/* border: 1px solid blue; */
		/* margin: 0; */
		padding: 0;
		width: 100%;
		align-items: unset;
	}

	.icon-with-description {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
	}

	.section-content-description li .flc-list-bullet {
		width: 0.8rem;
		height: 0.8rem;
	}

	.section-content-description ul {
		padding: 0;
		padding-left: 0.5rem;
	}

	.text-field input,
	.text-field textarea,
	.input-field-subject {
		font-size: 1rem;
	}

	form {
		width: 100%;
		/* justify-content:stretch; */
	}

	.footer-container {
		flex-direction: column;
		/* align-items: center; */
		height: unset;
		/* width: unset; */
		max-width: 100%;
		padding: 0;
		/* margin: 0; */
	}

	.footer-items {
		position: relative;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 0;
		width: unset;
		flex-wrap: wrap;
	}

	.footer-categories {
		flex-direction: column;
		height: unset;
		max-height: 100%;
		overflow: visible;
		position: relative;
		padding: 0;
		margin: 1rem 0;
		flex: 0 0 50%;
	}

	.footer-categories-outline {
		margin: 1rem 0;
	}

	.footer-container a {
		margin: 0 0.5rem;
		font-size: 0.8rem;
	}

	.footer-categories:hover {
		box-shadow: none;
		outline: none;
	}
	.footer-categories:hover .footer-categories-outline,
	.footer-categories:hover .footer-categories-gradient {
		display: none;
	}
}

@media only screen and (max-width: 775px) {
	/* For tablets: */

	.home-cards-container {
		flex-wrap: wrap;
	}

	.page-container {
		flex-direction: column;
	}

	h1.description-large {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.5rem;
	}

	.collapsible-nav,
	#hidden {
		width: calc(100% - 6rem);
		height: 5rem;
		display: flex;
		position: absolute;
		flex-direction: column;
		justify-content: flex-end;
		top: 0;
		right: 0;
	}
	.collapsible-nav .collapsible-nav-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0;
		border: none;
		border-bottom: 4px solid #00cc33;
		background-color: transparent;
		color: black;
		height: 100%;
		width: 100%;
	}
	.collapsible-nav .collapsible-nav-btn:hover {
		background-color: rgb(236, 236, 236, 1);
	}
	.navbar {
		display: none;
	}

	#hidden .navbar {
		display: flex;
		padding: 0;
		padding-top: 4px;
		position: absolute;
		top: 5rem;
		right: 0;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
		width: calc(100% + 6rem);
		margin: 0;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 1) 75%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	#hidden .navbar-item-link {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 0;
	}

	.App-header {
		display: flex;
		justify-content: flex-start;
	}

	.App-header a.active {
		height: 6rem;
	}

	.navbar .flc-button {
		margin-top: 0.5rem;
	}

	.navbar-logo {
		margin: 1rem;
		margin-bottom: 0;
		padding: 0;
		height: 4rem;
		width: 4rem;
	}

	.active-navbar-item {
		background-color: #00cc33;
		border-bottom: none;
		color: white;
	}

	a.active-navbar-item:hover {
		background-color: #00cc33;
	}

	.flc-link-inline {
		border-bottom-width: 1px;
	}

	.footer-items {
		flex-direction: column;
	}
}

@media only screen and (max-width: 360px) {
	body,
	.page-container,
	.App {
		width: 100vw;
		min-width: 100vw;
		margin: 0;
		padding: 0;
	}

	.App {
		width: 100vw;
		display: flex;
		padding: 0;
		margin: 0;
		position: relative;
	}

	.App-header {
		display: flex;
		flex-direction: row;
		margin-bottom: 20rem;
		padding: 0;
	}

	.navbar-logo {
		width: 5rem;
		margin: 0;
		padding: 0;
	}

	/* .icon-with-description {
		display: flex;
		flex-direction: column;
	} */

	#where .section-with-title-content img {
		max-width: 100vw;
	}

	.footer-container {
		min-width: 100vw;
		width: 100vw;
		margin: 0;
		padding: 0;
		position: relative;
		display: flex;
		flex-direction: column;
	}
	.footer-items {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100vw;
		min-width: 100vw;
		position: relative;
	}
	.animated-gradient-line {
		position: absolute;
		top: 0;
	}
}
