.flc-link-block,
.flc-link-inline {
	border-bottom: 3px solid #333399;
	font-weight: bolder;
	text-decoration: none;
	color: #333399;
	width: fit-content;
}

.flc-link-block:active,
.flc-link-inline:active {
	font-weight: normal;
}

.flc-link-block:visited,
.flc-link-inline:visited {
	border-bottom-color: #93278f;
	color: #93278f;
}

.flc-link-block {
	display: block;
	margin: 0.5rem 0;
}

.flc-link-inline {
	display: inline;
	margin-left: 0.5rem;
}
