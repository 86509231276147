.icon-with-title {
	display: flex;
	flex-flow: column;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	width: 16rem;
	height: 16rem;
	text-align: center;
	padding: 1rem;
	margin: 0 auto;
	justify-content: flex-start;
	text-align: center;
	background-color: white;
	transition: 200ms ease;
}

.icon-with-title img {
	border-radius: 200px;
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	position: relative;
	transition: 200ms ease;
}

.icon-with-title:hover {
	background-color: white;
	overflow: hidden;
	outline: 1px solid black;
	border-radius: 4px;
	box-shadow: 10px 10px 15px grey;
	transition: 200ms ease;
	display: flex;
	height: 24rem;
	z-index: 1;
}

.icon-with-title:hover p,
.icon-with-title:hover h4 {
	display: inherit;
}

.icon-with-title:hover h3 {
	width: 85%;
	transition: 200ms ease;
	position: relative;
}

.icon-with-title:hover img {
	transform: rotate(-5deg);
	opacity: 1;
	transition: 200ms ease;
}

.icon-with-title:hover .icon-decorative-border {
	opacity: 1;
	transition: 500ms ease;
}

.icon-with-title .icon-description h4 {
	margin: 0 auto;
	padding: 0 auto;
	font-style: italic;
	margin-bottom: 0.5rem;
	padding-top: 1rem;
	justify-content: space-around;
}

.icon-decorative-border {
	border: 4px solid #cc3333;
	display: flex;
	margin: 0.6rem;
	background-color: transparent;
	position: absolute;
	width: 80%;
	height: 80%;
	opacity: 0;
	transition: 100ms ease;
}

.icon-decorative-gradient {
	background-color: transparent;
	display: flex;
	margin: 1.5rem;
	position: absolute;
	width: 85%;
	height: 85%;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0) 40%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(255, 255, 255, 1) 80%
	);
	display: flex;
	align-items: flex-end;
	justify-content: space-around;
}

.icon-container {
	position: relative;
}

.icon-description {
	position: relative;
	margin-top: -2rem;
	width: 100%;
}

.icon-description h3 {
	display: flex;
	position: relative;
	font-weight: normal;
	margin: 0 auto;
	padding: 0 auto;
	width: 100%;
	transition: 200ms ease;
	background-color: white;
	align-items: center;
	justify-content: space-around;
}

.icon-description p {
	margin: 0 auto;
	padding: 0 auto;
}

.icon-description h4,
.icon-description p {
	display: none;
	position: relative;
	transition: 100ms ease;
}
