.privacy-and-terms-container {
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  margin: 0 10rem;
  /* padding: 0 4rem; */
}

.privacy-and-terms-container ul li {
  margin: 1rem;
}

.privacy-and-terms-container p {
  margin-top: 2rem;
}

@media only screen and (max-width: 775px) {
  .privacy-and-terms-container {
    padding: 0;
    margin: 0 2rem;
  }
}
