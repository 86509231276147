.home-container {
	/* border: 1px solid green; */
	background-image: url('../../assets/images/Circuitry.png');
	background-repeat: no-repeat;
	background-position: center;
	background-position-y: -0px;
	/* background-size: 2780px; */
	/* min-width: 100vw; */
	/* object-position: 100% 0; */
	/* position: fixed; */
	margin-bottom: 5rem;
	width: 100%;
}

.home-page-description {
	/* border: 1px solid green; */
	/* margin-top: 2rem; */
	margin-bottom: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* height: 272px; */
	/* box-shadow: 0px 0px 200px 10px #000; */
}

.home-page-description + button {
	/* font-size: calc(10px + 2vmin); */
}

.home-cards-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.learn-more-container {
	position: 'relative';
	display: flex;
	justify-content: center;
	align-items: center;
}

.learn-more-container:active {
	-webkit-transition: 0s;
	transition: 0s;
	background-color: green;
}

.learn-more-container:hover .flc-button {
	padding-right: 2.5rem;
}

.learn-more-container:hover .flc-button::after {
	opacity: 1;
	right: 1rem;
}

.learn-more-container .flc-button {
	position: absolute;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.learn-more-container .flc-button::after {
	content: '\00bb';
	position: absolute;
	opacity: 0;
	right: 3rem;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.learn-more-container a.flc-button:active {
	-webkit-transition: 0s;
	transition: 0s;
	border-width: 0;
	background-color: green;
	color: white;
}

.learn-more-container .flc-button:hover {
	background-color: #00cc33;
}
