.contact-page-container {
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	padding-bottom: 2rem;
}

.contact-page-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contact-page-fields {
	padding: 1rem;
	display: grid;
	grid-template-areas:
		'email email'
		'name phone'
		'subject subject'
		'message message';
	grid-gap: 3rem;
	margin: 2rem;
	grid-template-columns: auto;
}

.text-field {
	display: flex;
	flex-direction: column;
}

.text-field img {
	height: 1.5rem;
	margin-left: 2rem;
}

.text-field input,
.text-field textarea,
.text-field-subject {
	border: none;
	border-bottom: 4px solid #00cc33;
	background-color: #e6e6e6;
	padding: 1rem 1.5rem;
	font-size: 1.5rem;
	font-family: 'Montserrat', sans-serif;
}

#fullName input {
	border-color: #00cc33;
}

#fullName {
	grid-area: name;
}

#email input {
	border-color: #cc3333;
}

#email {
	grid-area: email;
}

#phone {
	grid-area: phone;
}

#subject ul,
#subject ul li,
#subject ul li span {
	/* display: flex; */
	width: 100%;
}

#subject {
	grid-area: subject;
}

#subject .input-field-subject {
	border-color: #333399;
}

#message textarea {
	border-color: #93278f;
	height: 10rem;
	resize: none;
}

#message {
	grid-area: message;
}

.confirmation-message {
	background-color: #88fca5;
	margin-bottom: 1rem;
	padding: 1rem;
	margin: 0rem;
	width: 100%;
}

.confirmation-message-container {
	display: flex;
	margin-top: 1rem;
	width: 100%;
}

/* Mobile */

@media only screen and (max-width: 1100px) {
	form {
		width: 100%;
		/* justify-content:stretch; */
	}

	.contact-page-fields {
		/* border: 1px solid blue; */
		/* position: relative; */
		/* width: 100%; */
		/* padding: 0; */
		display: grid;
		grid-template-areas:
			'email'
			'name'
			'phone'
			'subject'
			'message';
		grid-gap: 0;
		grid-row-gap: 1.5rem;
		margin: 2rem 8rem;
		grid-template-columns: auto;
	}
}

@media only screen and (max-width: 775px) {
	.App {
		min-width: 100%;
		margin: 0;
	}
	.page-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.contact-page-fields {
		margin: 2rem;
	}
}

@media only screen and (max-width: 360px) {
}
