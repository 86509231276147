.section-with-title {
	/* border: 1px solid blue; */
	border-bottom: 4px solid green;
	margin: 4rem 10rem;
	padding-bottom: 1rem;
	text-align: left;
	vertical-align: top;
}

@media only screen and (max-width: 775px) {
	.section-with-title {
		margin: 2rem;
	}
}

@media only screen and (max-width: 360px) {
	.section-with-title {
		padding: 1rem;
		margin: 0;
	}
}
