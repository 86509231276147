.icon-with-description-card-container {
	/* border: 3px solid red; */
	margin: 0 4rem;
	max-width: 310px;
}
.icon-with-description-card {
	display: flex;
	flex-direction: column;
	/* border: 3px solid blue; */
	/* height: 100%; */
	/* max-height: 250px; */
	justify-content: space-between;
	align-items: space-between;
}
.icon-with-description-icon-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	/* border: 3px solid green; */
}
.icon-with-description-icon {
	max-height: 200px;
	/* max-height: 200px; */
}
.icon-with-description-description-container {
	/* border: 3px solid blue; */
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	/* border: 3px solid purple; */
}
.icon-with-description-description {
	text-align: justify;
	/* max-width: 100px; */
}
.icon-with-description-children-container {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-top: 2rem;
	position: relative;
}
.icon-with-description-button {
}

@media only screen and (max-width: 1100px) {
	.icon-with-description-card-container {
		margin: 0 1rem;
		margin-top: 4rem;
	}
}
