.navbar {
	/* border: 1px solid red; */
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	flex-flow: row wrap;
	width: 100%;
	margin-right: 3rem;
	padding: 2rem 0;
}

.navbar-item {
	display: flex;
	padding: 0.5rem 1.5rem;
	font-size: 1.5rem;
}

.navbar-item-link {
	color: black;
	text-decoration: none;
}

a.navbar-item-link:hover {
	background-color: rgb(236, 236, 236, 1);
	text-decoration: none;
}

.active-navbar-item {
	border-bottom: 4px solid #00cc33;
}
