.flc-lightbulb-container {
  border: 1px solid blue;
  width: 10rem;
  height: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
}
.flc-lightbulb-case:hover .flc-lightbulb-ring {
  /* border: 1px solid black; */
  /* background-color: yellow; */
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-image: radial-gradient(yellow 30%, transparent 60%);
  /* background-image: radial-gradient(red 5%, green 15%, blue 60%); */
  position: absolute;
}
.flc-lightbulb-case {
  border: 1px solid black;
  /* background-color: yellow; */
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  /* position: absolute; */
}
.flc-lightbulb {
  display: flex;
  border: 1px solid black;
  /* background-color: yellow; */
  border-radius: 1000px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
}
