$header-height: 12rem;
$logo-dimensions: calc($header-height - 2rem);

.App-header {
	// border: 1px solid teal;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	position: fixed;
	z-index: 100;
	width: 100%;
	height: $header-height;
	max-width: 100rem;
}

.header-spacer {
	width: 100%;
	height: $header-height;
	max-width: 100rem;
}

.navbar-logo {
	margin: 1rem;
	margin-left: 3rem;
	width: $logo-dimensions;
	height: $logo-dimensions;
}

.white-fill {
	display: block;
	float: inline-end;
	position: absolute;
	margin: 0 auto;
	width: 100%;
	height: 70%;
	background-color: white;
	z-index: -1;
	top: 0px;
}

.gradient-fill {
	display: block;
	float: inline-end;
	position: absolute;
	margin: 0 auto;
	width: 100%;
	height: 30%;
  background-image: linear-gradient(white, transparent);
	z-index: -1;
	bottom: 0px;
}

.collapsible-nav {
	width: 100%;

}

.collapsible-nav-btn {
	display: none;
}