.section-with-title-content {
	/* border: 1px solid green; */
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-left: 3.8rem;
	padding-bottom: 1rem;
	position: relative;
}

@media only screen and (max-width: 775px) {
	.section-with-title-content {
		max-width: 100vw;
		margin: 0;
	}
}

@media only screen and (max-width: 360px) {
	.section-with-title-content {
		max-width: 100vw;
		margin: 0;
	}
}
