.footer-container {
	/* border: 1px solid green; */
	margin: 0 auto;
	margin-top: 2rem;
	display: flex;
	flex-flow: column;
	align-items: center;
	position: relative;
	height: 8rem;
	width: 100%;
}

.footer-container .animated-gradient-line {
	width: 90%;
}

.footer-items {
	position: absolute;
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	width: 100%;
	bottom: 0;
	justify-content: space-around;
	align-items: flex-end;
}

.footer-categories {
	display: flex;
	background-color: white;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	margin-bottom: 1rem;
	padding: 1rem 0;
	padding-top: 2rem;
	bottom: 0;
	min-width: 15rem;
	max-height: 1rem;
	height: 1.4rem;
	overflow: hidden;
	position: relative;
	z-index: 1;
	border-radius: 4px;
	transition: 50ms linear;
}

.footer-categories:hover {
	height: 100%;
	cursor: default;
	box-shadow: 10px 10px 15px grey;
	outline: 1px solid black;
	max-height: 100rem;
	z-index: 10;
	transition: max-height 500ms linear;
}

.footer-categories h3 {
	margin: 1rem auto;
	font-size: 1rem;
	z-index: 1;
}

.footer-categories a {
	color: black;
	font-weight: normal;
	text-decoration: none;
	padding: 0.4rem 1rem;
	width: auto;
	display: flex;
	text-align: left;
	z-index: 1;
	margin: 0 2rem;
}

.footer-categories a:hover {
	text-decoration: underline;
}

.footer-categories:hover .footer-categories-outline {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	padding: 0;
}

.footer-categories-outline {
	position: absolute;
	outline: 4px solid blue;
	display: none;
	height: auto;
	flex-grow: 1;
	bottom: 0;
	left: 0;
	top: 0;
	right: 0;
	margin: 1.3rem;
	padding: 0;
}

.footer-categories:hover .footer-categories-gradient {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 82%;
	padding: 0;
	background-color: transparent;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0) 40%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(255, 255, 255, 1) 80%
	);
}
