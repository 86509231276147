.animated-gradient-line {
	height: 4px;
	width: 100%;
	background: linear-gradient(to right, #cc3333, #00cc33, #333399, #93278f);
	background-size: 800% 800%;

	-webkit-animation: AnimatedGradientLine 21s ease infinite;
	-moz-animation: AnimatedGradientLine 21s ease infinite;
	animation: AnimatedGradientLine 21s ease infinite;
}

@-webkit-keyframes AnimatedGradientLine {
	0% {
		background-position: 0% 51%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 51%;
	}
}
@-moz-keyframes AnimatedGradientLine {
	0% {
		background-position: 0% 51%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 51%;
	}
}
@keyframes AnimatedGradientLine {
	0% {
		background-position: 0% 51%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 51%;
	}
}
