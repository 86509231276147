@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.App {
	/* border: 1px solid red; */
	position: relative;
	display: flex;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	text-align: center;
	/* justify-content: center; */
	align-items: center;
}

.App-link {
	color: #61dafb;
}

button {
	border: none;
}

h1 {
	font-weight: normal;
	padding: 0 auto;
}

h2 {
	font-size: 2rem;
	font-weight: normal;
}

h3 {
	font-size: 1.8rem;
}

.description-large {
	margin: 0 auto;
	font-size: 3.5rem;
}

.description-small {
	font-size: 2rem;
	margin: 3rem 2rem;
}

.subtitle {
	font-size: 1.4rem;
	margin: 3rem 2rem;
}

.section-content img {
	max-width: 100%;
}

.section-content-description {
	font-size: 1.6rem;
	width: 100%;
	line-height: 2.2rem;
}

.section-content-description ul {
	margin: 0 auto;
	margin-top: 2rem;
	list-style: none;
}

.section-content-description li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
}

.section-content-description li .flc-list-bullet {
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: #333399;
	border-radius: 20px;
	margin: 0;
	padding: 0;
}

.section-content-description li p {
	padding-left: 2rem;
}

.text-links-in-paragraph {
	border-bottom: 3px solid #333399;
	font-weight: bolder;
	text-decoration: none;
	color: #333399;
}

.text-links-in-paragraph:hover {
	font-weight: normal;
	border-bottom: 2px solid #333399;
}

.text-links-in-paragraph:visited {
	border-bottom: 3px solid #93278f;
	color: #93278f;
}

div.App div div:last-child section {
	border-bottom: none;
	margin-bottom: 0;
}
