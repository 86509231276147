.flc-button {
	/* border: 1px solid green; */
	text-decoration: none;
	border-radius: 4px;
	background-color: #00cc33;
	color: white;
	font-family: 'Montserrat', sans-serif;
	padding: 0.5rem 1.5rem;
	font-size: 1.5rem;
	font-weight: normal;
}

.flc-button:visited {
	border: 1px solid green;
	text-decoration: none;
	background-color: #00cc33;
	color: white;
}

.flc-button:hover {
	cursor: pointer;
	background-color: green;
}

.flc-button:active {
	border: 1px solid green;
	background-color: white;
	color: black;
}
