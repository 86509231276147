.input-field {
	/* border: 1px solid green; */
	display: flex;
	flex-direction: column;
	position: relative;
}

.text-field-dropdown-title {
	display: flex;
	flex-direction: row;
	font-weight: bolder;
	padding-bottom: 0.2rem;
}

.input-field-short-title:after {
	/* border: 1px solid green; */
	content: '\25bc';
	font-size: 20px;
	color: #333399;
	right: 1.5rem;
	bottom: 1.6rem;
	margin: 0;
	position: absolute;
	pointer-events: none;
}

.input-field-short-title select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 100%;
	/* height: 50px; */
	margin: 5px 0px;
	/* padding: 0px 24px; */
	-ms-word-break: normal;
	word-break: normal;
}

.input-field-subject {
	border: 1px solid green;
	border: none;
	width: 100%;
	border-bottom: 4px solid #00cc33;
	background-color: #e6e6e6;
	padding: 1rem 1.5rem;
	font-size: 1.5rem;
	font-family: 'Montserrat', sans-serif;
}

select::-ms-expand {
	display: none;
}
