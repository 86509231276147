.icon-with-description {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}

.icon-with-description img {
  /* border: 1px solid green; */
  /* height: 10rem; */
  width: 16rem;
}

.icon-with-description img:hover {
  -webkit-animation: spin-r 80ms linear 2;
  -moz-animation: spin-r 80ms linear 2;
  animation: spin-r 80ms linear 2;
  -webkit-animation: spin-l 80ms linear 2;
  -moz-animation: spin-l 80ms linear 2;
  animation: spin-l 80ms linear 2;
}
@-moz-keyframes spin-r {
  100% {
    -moz-transform: rotate(3deg);
  }
}
@-webkit-keyframes spin-r {
  100% {
    -webkit-transform: rotate(3deg);
  }
}
@keyframes spin-r {
  100% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}
@-moz-keyframes spin-l {
  100% {
    -moz-transform: rotate(-3deg);
  }
}
@-webkit-keyframes spin-l {
  100% {
    -webkit-transform: rotate(-3deg);
  }
}
@keyframes spin-l {
  100% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}

.icon-with-description p {
  margin-left: 2rem;
}
